<template>
  <div>
     <br>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> User</p>
        <br>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datauser.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">

           <tr v-if="btn=='tambah'">        
              <td class="capitalize">username</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="text" id="username" name="username" class="form-control form-control-sm" placeholder="username" v-model="datauser.username" >
                  </div>
              </td>
          </tr>
           <tr v-if="btn=='tambah'">        
              <td class="capitalize">password</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="password" id="password" name="password" class="form-control form-control-sm" placeholder="password" v-model="datauser.password" >
                  </div>
              </td>
          </tr>
           <tr v-if="btn=='tambah'">        
              <td class="capitalize">Konfirmasi password</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="password" id="password" name="password" class="form-control form-control-sm" placeholder="password" v-model="password" >
                  </div>
                  <p class="text-red-600 text-sm" v-if="datauser.password!=password">Password tidak sama !</p>
              </td>
          </tr>
           <tr v-if="btn!='tambah'">        
              <td class="capitalize">name</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="text" id="name" name="name" class="form-control form-control-sm" placeholder="name" v-model="datauser.nama" >
                  </div>
              </td>
          </tr>
           <tr v-if="btn!='tambah'">        
              <td class="capitalize">level</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                 <select class='form-control' v-model="datauser.level">
                    <option v-for="(item, index) in levels" :key="index">{{item.level}}</option>
                 </select>
              </td>
          </tr>
           <tr v-if="btn!='tambah'">        
              <td class="capitalize">aktif</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                 <select class='form-control' v-model="datauser.aktif">
                 <option value="true">Aktif</option>
                 <option value="false">Tidak Aktif</option>
                 </select>
              </td>
          </tr>
           <tr>        
              <td class="capitalize">Warna background</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="text" id="warna" name="warna" class="form-control form-control-sm" placeholder="warna" v-model="datauser.warna" >
                  </div>
              </td>
          </tr>
          </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm capitalize"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span> Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datauser = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class="col-7 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
            <!-- TABLE PART -->
            <div v-if="datanya.length>0" class="p-3">
                <Btables :tables="datanya"  :cari="['']" :hide="['warna']" @selected="ambil" ref="btable" />
            </div>
            <!-- END TABLE PART -->
         </div>
        </div>
      </div>
    </form>
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
    layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datauser: {},
      btn: "tambah",
      datanya:[],
      levels:[],
      password:''
    };
  },
  methods: {
    tbuser(){
      this.$refs.btable.getData()
    },
    getData(){
      sdb.collection("tbuser",false).doc().select(`select id,username,password,level,name,nama,aktif,auth,email,warna from tbuser`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      
      sdb.collection("app_kasir_level",false).doc().select(`select * from app_kasir_level`).then(res => {
          this.levels=res;
          this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.datauser = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    async submit() {
      let that=this
      console.log(this.datauser)
      this.datauser.name=this.datauser.nama;
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          delete this.datauser.id;
          await sdb.collection('tbuser').register(this.datauser)
              that.getData();
              that.datauser;
              that.$router.go()
        } else if (this.btn == "update") {
          await sdb
            .collection("tbuser")
            .doc()
            .set(this.datauser)
              that.getData();
            that.datauser={}
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
          await sdb
            .collection("tbuser")
            .doc(this.datauser.id)
            .delete()
              that.getData();
            that.datauser={}
            // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>